/* You can add global styles to this file, and also import other style files */
@import 'aos/dist/aos.css';

.header{
  display: flex;
  justify-content: center;
}
* {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
